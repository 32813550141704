<template>
    <div class="pagebox">
        <div class="test_result_box">
            <div class="test_result_con">

                <!-- 操作栏 -->
                <div class="clear"></div>
                <div class="user_result_all">

                    <!-- 测评结果（转换PDF打印的容器） -->
                    <div class="user_result_box" id="user_result_pdf_box">
                        <div class="print_box">
                            <img alt="" :src="Print_Box_Img" id="Print_Box_Img_Id" />
                        </div>
                        <!-- 封面 -->
                        <div class="user_result_img_box">
                            <div class="radius1"></div>
                            <div class="radius2"></div>
                            <div class="radius3"></div>
                            <div class="radius4"></div>
                            <div class="radius5"></div>
                            <div class="radius6"></div>
                            <div class="radius7"></div>
                            <div class="radius8"></div>
                            <div class="user_box">
                                <div class="clear"></div>
                                <div class="info" style="margin-top:60px;" v-if="UserStudentData.Student!=null">
                                    <span class="t">姓名：</span>
                                    <span class="x">{{UserStudentData.Student.StudentName}}</span>
                                </div>
                                <div class="info" style="margin-top:60px;" v-else>
                                    <span class="t">昵称：</span>
                                    <span class="x">{{UserStudentData.NickName}}</span>
                                </div>
                                <div class="info" v-if="UserStudentData.Student!=null">
                                    <span class="t">电话：</span>
                                    <span class="x">{{UserStudentData.Student.Mobile}}</span>
                                </div>
                                <div class="info">
                                    <span class="t">测评时间：</span>
                                    <span class="x">{{TestTime}}</span>
                                </div>
                                <!-- <div class="info">
                                    <span class="t">用时：</span>
                                    <span class="x">1小时20分</span>
                                </div> -->
                            </div>
                            <div class="clear"></div>
                            <div class="title_box">专业定位测评综合报告</div>
                            <div class="clear"></div>
                            <div class="type_box">
                                <div class="tbox">• 兴趣维度</div>
                                <div class="tbox">• 性格维度</div>
                                <div class="tbox">• 能力维度</div>
                                <div class="tbox">• 价值观维度</div>
                                <div class="tbox">• 学科维度</div>
                                <div class="tbox">• 专业方向推荐</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <!-- 一、兴趣维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">一、兴趣维度</div>
                                <div class="type_mark">
                                    <div class="mark_tips">测评简介</div>
                                    <div class="mark_txt">霍兰德 兴趣自测(Self- Search)是由美国职业指导专家霍兰德(John Holland)根据他本人大量的职业咨询经验及其职业类型理论编制的测评工具。霍兰德 认为，个人职业兴趣特性与职业之间应有一种内在的对应关系。根据兴趣的不同，人格可分为研究型(I)、艺术型(A)、社会型(S)、企业型(E)、常规型(C)、现实型(R)六个维度，每个人的性格都是这六个维度的不同程度组合。</div>
                                </div>
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit" style="float:left;">得分：</div>
                                        <div class="score">
                                            <div class="s" :class="'interest'+item.Category" v-for="(item,index) in AllroundInterestScore" :key="index">
                                                <span class="z">{{item.Category}}</span>
                                                <span class="t">（{{InterestCategoryStr(item.Category)}}）</span>
                                                <span class="n">{{item.Value}}<span class="f">分</span></span>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box">
                                            <div class="xqlx">
                                                <span class="tt">您的兴趣类型为：</span>
                                                <span class="jl_e">{{AllroundInterestResult.Code}}</span>
                                                <span class="jl_h">（{{InterestCategoryStr(AllroundInterestResult.Code.split('')[0])}}、{{InterestCategoryStr(AllroundInterestResult.Code.split('')[1])}}、{{InterestCategoryStr(AllroundInterestResult.Code.split('')[2])}}）</span>
                                            </div>
                                            <div class="jlmark" v-html="AllroundInterestResult.Result"></div>
                                        </div>
                                        <div class="ld_box">
                                            <div class="clear"></div>
                                            <span class="xqld">兴趣雷达</span>
                                            <div class="cbox" id="ResultChartBoxXQ"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box" v-if="AllroundInterestAdvise.length>0">
                                        <div class="df_box" style="width:100%;">
                                            <div class="xqlx" style="margin-top:10px;">
                                                <span class="tt">您的劣势兴趣为：</span>
                                                <span class="jl_e"><span v-for="(item,index) in AllroundInterestAdvise" :key="index">{{item.Code}}</span></span>
                                                <span class="jl_h">（<span v-for="(item,index) in AllroundInterestAdvise" :key="index">{{(index!=0?'、':'')+InterestCategoryStr(item.Code)}}</span>）</span>
                                            </div>
                                            <div class="jlmark" style="margin-top:20px;" v-for="(item,index) in AllroundInterestAdvise" :key="index"><span style="color:rgba(var(--themecolor),0.9);">{{InterestCategoryStr(item.Code)}}：</span>{{item.Result}}</div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <div class="type_table">
                                            <div class="list" v-for="(item,index) in AllroundInterestMarkSure" :key="index">
                                                <div class="head">
                                                    <div class="ttbox" :class="'interest'+item.Category">
                                                        <div class="e">{{item.Category}}</div>
                                                        <div class="z">（{{InterestCategoryStr(item.Category)}}）</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="t"><span>共同特征：</span></span><span class="x" v-html="item.Feature"></span></div>
                                                    <div class="tz"><span class="t"><span>性格特点：</span></span><span class="x">{{ item.Trait}}</span></div>
                                                    <div class="tz"><span class="t"><span>别人评价：</span></span><span class="x">{{ item.Evaluate}}</span></div>
                                                    <div class="tz"><span class="t"><span>喜欢活动：</span></span><span class="x" v-html="item.Activity"></span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type" v-for="(item,index) in InterestRecommendMajor" :key="index">
                                            <div class="tj_tit"><span class="tj_ico"></span>{{item.Category}}</div>
                                            <div class="tj_box">
                                                <span class="b" v-for="(it,ind) in item.MajorList" :key="ind">{{it.Name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                        <!-- 二、性格维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">二、性格维度</div>
                                <div class="type_mark">
                                    <div class="mark_tips">测评简介</div>
                                    <div class="mark_txt">1942年,瑞土精神分析学家荣格第一次提出人格分类的概念。他认为感知和判断是大脑的两大基本功能。不同的人,感知倾向不同，有些人更侧重直觉,有些更侧重感觉。同样,不同的人判断倾向也不同——有些更倾向理性分析得出结论,有些更侧重情感,更为感性。同时,这两大基本功能又受到精力来源不同(内向或外向)的影响。以荣格的人格分类理论为基础,美国心理学家Katherine Cookriggs(1875-1968)和 Isabel Briggs Myers母女提出了影响大脑做出决定的第四因素,行为和生活方式；并综合荣格的人格分类学说开成MBTI人格模型。</div>
                                </div>
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit">得分：</div>
                                        <!-- 分数图表 -->
                                        <div class="score s_chart_box">
                                            <div class="xg_l_box">
                                                <div class="l_box"><span>10</span></div>
                                                <div class="l_box"><span>8</span></div>
                                                <div class="l_box"><span>6</span></div>
                                                <div class="l_box"><span>4</span></div>
                                                <div class="l_box"><span>2</span></div>
                                                <div class="l_box"><span>0</span></div>
                                            </div>
                                            <div class="xg_s_box">
                                                <div class="xg_item" v-for="(item,index) in AllroundCharacterScore" :key="index">
                                                    <div class="item" v-for="(it,ind) in item.Score" :key="ind">
                                                        <div class="name">{{CharacterCategoryStr(it.Category)}}<br/>({{it.Category}})</div>
                                                        <div class="number" :style="{'height':(it.Value*10)+'%'}"><span class="num">{{it.Value}}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box" style="width:100%;">
                                            <div class="xqlx" style="margin-top:0px;">
                                                <span class="tt">您的性格类型：</span>
                                                <span class="jl_e">{{AllroundCharacterResult.Code}}</span>
                                                <span class="jl_h">（{{CharacterCategoryStr(AllroundCharacterResult.Code[0])}}、{{CharacterCategoryStr(AllroundCharacterResult.Code[1])}}、{{CharacterCategoryStr(AllroundCharacterResult.Code[2])}}、{{CharacterCategoryStr(AllroundCharacterResult.Code[3])}}）</span>
                                            </div>
                                            <div class="xqlx" style="margin-top:0px;"><span class="tt">性格关键词：</span><span class="jl_g">{{AllroundCharacterResult.Keyword}}</span></div>
                                            <div class="xqlx" style="margin-top:0px;"><span class="tt">人格类型：</span><span class="jl_g">{{AllroundCharacterResult.Individuality}}</span></div>
                                            <div class="jlmark" style="margin-top:30px;" v-html="AllroundCharacterResult.Result"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type" v-for="(item,index) in CharacterRecommendMajor" :key="index">
                                            <div class="tj_tit"><span class="tj_ico"></span>{{item.Category}}</div>
                                            <div class="tj_box">
                                                <span class="b" v-for="(it,ind) in item.MajorList" :key="ind">{{it.Name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                        <!-- 三、能力维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">三、能力维度</div>
                                <div class="type_mark">
                                    <div class="mark_tips">测评简介</div>
                                    <div class="mark_txt">多元智能（multiple intelligence）由美国哈佛大学教育研究院心理学家霍华德・加德纳博士1983年提出。加德纳从研究脑部创伤病人发觉到他们在学习能力上的差异，从而提出多元智能理论。加德纳认为智力是“一种处理信息的生理心理潜能，这种潜能在某种文化背景之下，会被引发去解决问题或是创作该文化所重视的作品。”</div>
                                </div>
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit">得分：</div>
                                        <!-- 分数图表 -->
                                        <div class="score s_chart_box">
                                            <div class="xg_l_box">
                                                <div class="l_box" style="height:calc(25% - 1px);"><span>20</span></div>
                                                <div class="l_box" style="height:calc(25% - 1px);"><span>15</span></div>
                                                <div class="l_box" style="height:calc(25% - 1px);"><span>10</span></div>
                                                <div class="l_box" style="height:calc(25% - 1px);"><span>5</span></div>
                                                <div class="l_box" style="height:calc(25% - 1px);"><span>0</span></div>
                                            </div>
                                            <div class="xg_s_box">
                                                <div class="xg_item nl_item" v-for="(item,index) in AllroundAbilityScore" :key="index">
                                                    <div class="item">
                                                        <div class="name">{{item.Category}}</div>
                                                        <div class="number" :style="{'height':(item.Value*5)+'%'}"><span class="num">{{item.Value}}<span class="tip" :class="getAbilityClass(item.Value)">{{getAbilityScoreLowOrHigh(item.Value)}}</span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box" style="width:100%;">
                                            <div class="xqlx" style="margin-top:0px;">
                                                <span class="tt">您的优势能力为：</span><span class="jl_e ch">
                                                    <span v-for="(item,index) in AllroundAbilityHighAdvise" :key="index">{{(index!=0?'+':'')+item.Category}}</span>
                                                </span>
                                            </div>
                                            <div class="jlmark" style="margin-top:20px;" v-for="(item,index) in AllroundAbilityHighAdvise" :key="index"><span style="color:rgba(var(--themecolor),0.9);">{{item.Category}}：</span>{{item.HighResult}}</div>
                                            <div v-if="AllroundAbilityLowAdvise.length>0">
                                                <div class="xqlx">
                                                    <span class="tt">您的劣势能力为：</span><span class="jl_e ch">
                                                        <span v-for="(item,index) in AllroundAbilityLowAdvise" :key="index">{{(index!=0?'+':'')+item.Category}}</span>
                                                    </span>
                                                </div>
                                                <div class="jlmark" style="margin-top:20px;" v-for="(item,index) in AllroundAbilityLowAdvise" :key="index"><span style="color:rgba(var(--themecolor),0.9);">{{item.Category}}：</span>{{item.LowResult}}</div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <div class="type_table">
                                            <div class="list" v-for="(item,index) in AllroundAbilityResult" :key="index">
                                                <div class="head">
                                                    <div class="ttbox">
                                                        <div class="y">{{item.Category}}</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="m">{{item.Result}}</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type" v-for="(item,index) in AbilityRecommendMajor" :key="index">
                                            <div class="tj_tit"><span class="tj_ico"></span>{{item.Category}}</div>
                                            <div class="tj_box">
                                                <span class="b" v-for="(it,ind) in item.MajorList" :key="ind">{{it.Name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                        <!-- 四、职业倾向维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">四、价值观维度</div>
                                <div class="type_mark">
                                    <div class="mark_tips">测评简介</div>
                                    <div class="mark_txt">职业价值观理论产生于在职业生涯规划领域具有“教父”级地位的美国麻省理工大学斯隆商学院、美国著名的职业指导专家埃德加·H·施恩教授领导的专门研究小组，是对该学院毕业生的职业生涯研究中演绎成的。斯隆管理学院的44名MBA毕业生，自愿形成一个小组接受施恩教授长达12年的职业生涯研究，包括面谈、跟踪调查、公司调查、人才测评、问卷等多种方式，最终分析总结出了职业锚(又称职业定位)理论。</div>
                                </div>
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit">得分：</div>
                                        <!-- 分数图表 -->
                                        <div class="score s_chart_box">
                                            <div class="xg_l_box">
                                                <div class="l_box"><span>10</span></div>
                                                <div class="l_box"><span>8</span></div>
                                                <div class="l_box"><span>6</span></div>
                                                <div class="l_box"><span>4</span></div>
                                                <div class="l_box"><span>2</span></div>
                                                <div class="l_box"><span>0</span></div>
                                            </div>
                                            <div class="xg_s_box">
                                                <div class="xg_item nl_item zy_item" v-for="(item,index) in AllroundValueScore" :key="index">
                                                    <div class="item">
                                                        <div class="name">{{item.Category}}型</div>
                                                        <div class="number" :style="{'height':(item.Value*10)+'%'}"><span class="num">{{item.Value}}<span class="tip" :class="getValueClass(item.Value)">{{getValueScoreLowOrHigh(item.Value)}}</span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box" style="width:100%;">
                                            <div class="xqlx" style="margin-top:0px;"><span class="tt">您的价值观类型为：</span>
                                                <span class="jl_e ch"><span v-for="(item,index) in AllroundValueResult.slice(0, 3)" :key="index">{{(index!=0?'+':'')+item.Category+'型'}}</span></span>
                                            </div>
                                            <!-- <div class="xqlx" style="margin-top:0px;"><span class="tt">职业关键词：</span><span class="jl_g">沟通协调、运筹帷幄</span></div>
                                            <div class="jlmark" style="margin-top:30px;">
                                                你最适合的职业类型是升迁到组织中更高的管理职位，进入管理层或者成为总经理。这样能够整合其他人的工作，并对组织中某项工作的绩效承担责任。
                                            </div> -->
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <div class="type_table">
                                            <div class="list" v-for="(item,index) in AllroundValueResult" :key="index">
                                                <div class="head">
                                                    <div class="ttbox">
                                                        <div class="y">{{item.Category}}型</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="m">{{item.Result}}</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type" v-for="(item,index) in ValueRecommendMajor" :key="index">
                                            <div class="tj_tit"><span class="tj_ico"></span>{{item.Category}}</div>
                                            <div class="tj_box">
                                                <span class="b" v-for="(it,ind) in item.MajorList" :key="ind">{{it.Name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                        <!-- 五、学科维度 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">五、学科维度</div>
                                <div class="type_mark">
                                    <div class="mark_tips">测评简介</div>
                                    <div class="mark_txt">学科倾向测评，是综合评估高中生学科倾向的有效工具，是升学指导测验系列量表之一。测试的要素主要包含思维方式、学科兴趣、学科能力倾向和职业兴趣四个方面。通过专业的题目设计、严谨的解释分析，本测试能够为学生在未来就业方向提供参考。高中阶段正确的规划未来的职业发展，将为学员日后的生活和工作奠定良好的基础。</div>
                                </div>
                                <div class="type_result">
                                    <div class="tit_t">测评结果</div>
                                    <div class="score_box">
                                        <div class="s_tit">得分：</div>
                                        <!-- 分数图表 -->
                                        <div class="score s_chart_box">
                                            <div class="xg_l_box">
                                                <div class="l_box"><span>10</span></div>
                                                <div class="l_box"><span>8</span></div>
                                                <div class="l_box"><span>6</span></div>
                                                <div class="l_box"><span>4</span></div>
                                                <div class="l_box"><span>2</span></div>
                                                <div class="l_box"><span>0</span></div>
                                            </div>
                                            <div class="xg_s_box">
                                                <div class="xg_item xk_item" v-for="(item,index) in AllroundSubjectScore" :key="index">
                                                    <div class="item">
                                                        <div class="name">{{item.Category}}</div>
                                                        <div class="number" :style="{'height':(item.Value*10)+'%'}"><span class="num">{{item.Value}}<span class="tip" :class="getSubjectClass(item.Value)">{{getSubjectScoreLowOrHigh(item.Value)}}</span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="df_box" style="width:100%;"><div class="xqlx" style="margin-top:0px;">
                                                <span class="tt">您的优势学科为：</span><span class="jl_e ch">
                                                    <span v-for="(item,index) in AllroundSubjectHighAdvise" :key="index">{{(index!=0?'+':'')+item.Subject}}</span>
                                                </span>
                                            </div>
                                            <div class="xqlx" style="margin-top:0px;"><span class="tt">学科关键词：</span><span class="jl_g" v-if="AllroundSubjectHighAdvise.length>0">{{AllroundSubjectHighAdvise[0].Keyword}}</span></div>
                                            <div class="jlmark" style="margin-top:20px;" v-for="(item,index) in AllroundSubjectHighAdvise" :key="index"><span style="color:rgba(var(--themecolor),0.9);">{{item.Subject}}：</span>{{item.HighResult}}</div>
                                            <div v-if="AllroundSubjectLowAdvise.length>0">
                                                <div class="xqlx">
                                                    <span class="tt">您的劣势学科为：</span><span class="jl_e ch">
                                                        <span v-for="(item,index) in AllroundSubjectLowAdvise" :key="index">{{(index!=0?'+':'')+item.Subject}}</span>
                                                    </span>
                                                </div>
                                                <div class="jlmark" style="margin-top:20px;" v-for="(item,index) in AllroundSubjectLowAdvise" :key="index"><span style="color:rgba(var(--themecolor),0.9);">{{item.Subject}}：</span>{{item.LowResult}}</div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="score_box">
                                        <div class="bt_tit">推荐专业：</div>
                                        <div class="tj_type" v-for="(item,index) in SubjectRecommendMajor" :key="index">
                                            <div class="tj_tit"><span class="tj_ico"></span>{{item.Category}}</div>
                                            <div class="tj_box">
                                                <span class="b" v-for="(it,ind) in item.MajorList" :key="ind">{{it.Name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                        <!-- 六、专业方向推荐 -->
                        <div class="user_result_img_box">
                            <div class="type_con">
                                <div class="type_name">六、专业方向推荐</div>
                                <div class="type_result">
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <!-- <div class="bt_tit" style="padding-top:0px;">推荐专业：</div> -->
                                        <div class="df_box" style="width:100%;">
                                            <div class="jlmark" style="margin-top:20px;font-size:13px;">数据说明：匹配度指测试者的当前测评结果与各个专业要求的匹配程度，以下推荐仅作为参考，学生可依据自己的真实情况再进行选择。</div>
                                        </div>
                                        
                                        <!-- <div class="type_table">
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox zyfx">
                                                        <div class="x">艺术学</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="m">包含设计学类，美术学类，艺术学理论类，音乐与舞蹈学类，戏剧与影视学类5个中类专业</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox zyfx">
                                                        <div class="x">管理学</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="m">包含公共管理类，电子商务类，工商管理类，物流管理与工程类，图书情报与档案管理类，农业经济管理类，旅游管理类，管理科学与工程类，工业工程类9个中类专业</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="list">
                                                <div class="head">
                                                    <div class="ttbox zyfx">
                                                        <div class="x">医学</div>
                                                    </div>
                                                </div>
                                                <div class="text">
                                                    <div class="tz"><span class="m">含临床医学类，口腔医学类，护理学类，公共卫生与预防医学类，中医学类，中西医结合类，药学类，中药学类，医学技术类，基础医学类等11个中类专业</span></div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div> -->
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="score_box">
                                        <div class="clear"></div>
                                        <div class="bt_tit">专业匹配率排行：</div>
                                        <div class="type_table">
                                            <div class="list">
                                                <div class="bt bt1">排名</div>
                                                <div class="bt bt2">专业(类)名称</div>
                                                <div class="bt bt3">所属大类</div>
                                                <div class="bt bt4">推荐原因</div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="order" v-for="(item,index) in AllroundMajorList" :key="index">
                                                <div class="sj sj1"><i class="iconfont" v-if="(index<3)" :style="getMajorIconColor(index)">&#xe64e;</i><span class="n" :style="{'color':(index<3?'#fff':'')}">{{index+1}}</span></div>
                                                <div class="sj sj2">{{item.Name}}</div>
                                                <div class="sj sj3">{{item.Category}}</div>
                                                <div class="sj sj4">综合匹配率:{{parseInt(item.TotalNumber)}}%</div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear" style="height:80px;"></div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>

        <!-- 加载效果 -->
        <div id="result_download_loading" style="display:none;width:100%;height:100%;position:fixed;left:0px;top:0px;z-index:888;background-color:rgba(0,0,0,0.2);">
            <div style="width:200px;height:80px;position:absolute;top:calc(50% - 40px);left:calc(50% - 100px);text-align:center;">
                <i class="el-icon-loading" style="font-size:40px;color:#fff;"></i>
                <span style="display:block;font-size:20px;color:#777;margin-top:10px;">报告生成中…</span>
            </div>
        </div>

    </div>
</template>
<script>
import html2canvas from "html2canvas";
import {getStore } from '@/utils/utils';
import API from '@/api/config';
export default{
    data(){
        return{
            UserLoginData:{},
            UserStudentData:{},
            TestTime:'',
            AllroundInterestScore:[],// 兴趣维度分数
            // 兴趣维度结论
            AllroundInterestResult:{
                Code:'',
                Result:''
            },
            AllroundInterestAdvise:[],// 兴趣维度建议
            // 兴趣固定类型介绍
            AllroundInterestMark:[
                {Category:'C',Feature:'1、喜欢常规的,有规则的活动,喜欢按照预先安排好的程序做事。<br/>2、尊重权威和规则,细心、有条理,关注细节。<br/>3、习惯接受他人的指挥和领导,不喜欢竞争。<br/>4、较为谨慎和保守,缺乏创造性,很少冒险,富有自我牺牲精神。',Trait:'有责任心、依赖性强、高效率、保守、细致、有耐心',Evaluate:'谨慎的,顺从的',Activity:'1、有详尽计划,可掌控的活动<br/>2、追求精确和细节的活动'},
                {Category:'A',Feature:'1、有创造力,乐于创造新颖、与众不同的东西。<br/>2、渴望表现自己的个性,实现自身的价值。<br/>3、做事理想化,追求完美,不重实际。<br/>4、具有一定的艺术才能和个性。',Trait:'有创造性、非传统的、敏感、容易情绪化、较冲动、不服从指挥',Evaluate:'有个性的,有创意的',Activity:'1、过程限制少,可以自由发挥的活动<br/>2、需要创意的活动'},
                {Category:'S',Feature:'1、喜欢与人交往、不断结交新朋友。<br/>2、善言谈、愿意教导帮助别人。<br/>3、关心社会问题、有社会责任感。<br/>4、寻求广泛的人际关系,比较看重社会义务和社会道德。',Trait:'为人友好、热情活跃、善解人意、外向、有爱心',Evaluate:'热心的,乐于助人的',Activity:'1、社会公益类活动<br/>2、帮助教导别人的活动'},
                {Category:'I',Feature:'1、思想家而非实干家，抽象思维能力强，求知欲强，肯动脑，善思考，不愿意动手。<br/>2、喜欢独立和富有创造性的工作。<br/>3、知识渊博，不善于领导他人。<br/>4、考虑问题理性，做事喜欢精确，喜欢逻辑分析和推理，乐于探讨未知领域',Trait:'坚定，有韧劲，为人好奇，喜欢钻研，独立性强',Evaluate:'爱钻研的，有智慧的',Activity:'1、抽象的，理论性的，概念性的活动<br/>2、需要弄清楚事情前因后果来龙去脉的活动'},
                {Category:'R',Feature:'1、喜欢使用工具从事操作性工作。<br/>2、动手能力强，做事手脚灵活，动作协调。<br/>3、不善言辞，不善交际。<br/>4、偏好具体任务，喜欢独立做事。',Trait:'踏实稳重，注重实际，感觉迟钝，谦逊，不讲究',Evaluate:'技艺精湛的，直率的',Activity:'1、工程技术类活动<br/>2、需要使用工具、机器，要求基本操作技能的活动'},
                {Category:'E',Feature:'1、追求权力和物质财富，具有领导才能。<br/>2、喜欢竞争、敢冒风险、有野心/抱负。<br/>3、为人务实，习惯以利益得失，权利、地位、金钱等来衡量做事的价值。<br/>4、务实，做事有较强的目的性。',Trait:'善辩、精力旺盛、独断、乐观自信、好交际、有支配愿望',Evaluate:'有活力的，有影响力的',Activity:'1、管理监督类活动<br/>2、需要领导他人，说服别人的活动'},
            ],
            AllroundInterestMarkSure:[],// 兴趣类型介绍
            LDScoreData:[],// 兴趣雷达分数值
            AllroundCharacterScore:[{Score:[]},{Score:[]},{Score:[]},{Score:[]}],// 性格维度分数
            // 性格维度结论
            AllroundCharacterResult:{
                Code:'',
                Result:'',
                Keyword:'',
                Individuality:''
            },
            AllroundAbilityScore:[],// 能力维度分数
            AllroundAbilityResult:[],// 能力维度结论
            AllroundAbilityHighAdvise:[],// 能力维度高分建议
            AllroundAbilityLowAdvise:[],// 能力维度低分建议
            AllroundAbilityResult:[],// 价值观维度结论
            AllroundValueScore:[],// 价值观维度分数
            AllroundValueResult:[],// 价值观维度结论
            AllroundSubjectScore:[],// 学科维度分数
            AllroundSubjectResult:[],// 学科维度结论
            AllroundSubjectLowAdvise:[],// 学科维度低分建议
            AllroundSubjectHighAdvise:[],// 学科维度高分建议
            AllroundMajorScore:[],// 推荐专业成绩
            AllroundMajorList:[],// 推荐专业列表
            InterestRecommendMajor:[],// 兴趣推荐专业
            CharacterRecommendMajor:[],// 性格推荐专业
            AbilityRecommendMajor:[],// 能力推荐专业
            ValueRecommendMajor:[],// 价值观推荐专业
            SubjectRecommendMajor:[],// 学科推荐专业
            Print_Box_Img:''
        }
    },
    mounted(){
        // //获取用户登录信息
        this.$store.dispatch('user/judgeLoginStatus').then(flag => {
            if (!flag) {
                this.$message.info('请先登录！');
                this.$router.push('/login');
            }
        })

        this.GetAllroundResult();// 获取综合测评结论及建议
        
    },
    methods:{

        // 获取综合测评结论及建议
        GetAllroundResult(){
            
            document.getElementById("result_download_loading").style.display = 'block';
            API.Personal.GetUserStudent().then(res => {
                if(res.Code===0){
                    if(res.Data!=null){
                        this.UserStudentData = res.Data;
                    }
                }
            });
            let tt = getStore('AllroundResultData');
            if(tt!=undefined&&tt!=null&&tt!=''){
                this.TestTime = JSON.parse(tt).CreateTime.substring(0,10);
            }

            let all_Result = getStore('AllroundResultData');
            if(all_Result!=undefined&&all_Result!=null&&all_Result!=''){
                let all_json = JSON.parse(JSON.parse(all_Result).Answers);

                // 兴趣
                let interest_lowScore = [];
                let obj_interest = all_json.find(f=>f.Dimensionality===1);
                if(obj_interest!=undefined&&obj_interest!=null&&obj_interest!=''){
                    this.AllroundInterestScore = obj_interest.ScoreList;
                    obj_interest.ScoreList.forEach(e => {
                        this.LDScoreData.push(e.Value);
                        if(this.GetInterestLowScoreAdvise(e.Value)){
                            interest_lowScore.push(e.Category);
                        }
                    });
                    this.GetResultChartBoxXQ(this.LDScoreData);// 兴趣分数图
                    obj_interest.Result.split('').forEach(m => {
                        let obj = this.AllroundInterestMark.find(f=>f.Category==m);
                        if(obj!=undefined){
                            this.AllroundInterestMarkSure.push(obj);
                        }
                    });
                }

                // 性格
                let obj_character = all_json.find(f=>f.Dimensionality===3);
                if(obj_character!=undefined&&obj_character!=null&&obj_character!=''){
                    obj_character.ScoreList.forEach(e=>{
                        if(e.Category=='E'||e.Category=='I'){
                            this.AllroundCharacterScore[0].Score.push(e);
                        }
                        if(e.Category=='S'||e.Category=='N'){
                            this.AllroundCharacterScore[1].Score.push(e);
                        }
                        if(e.Category=='T'||e.Category=='F'){
                            this.AllroundCharacterScore[2].Score.push(e);
                        }
                        if(e.Category=='J'||e.Category=='P'){
                            this.AllroundCharacterScore[3].Score.push(e);
                        }
                    })
                }

                // 能力
                let obj_ability = all_json.find(f=>f.Dimensionality===2);
                if(obj_ability!=undefined&&obj_ability!=null&&obj_ability!=''){
                    this.AllroundAbilityScore = obj_ability.ScoreList;
                }

                // 价值观
                let obj_value = all_json.find(f=>f.Dimensionality===4);
                if(obj_value!=undefined&&obj_value!=null&&obj_value!=''){
                    this.AllroundValueScore = obj_value.ScoreList;
                }

                // 学科
                let obj_subject = all_json.find(f=>f.Dimensionality===5);
                if(obj_subject!=undefined&&obj_subject!=null&&obj_subject!=''){
                    this.AllroundSubjectScore = obj_subject.ScoreList;
                }

                // 推荐专业
                let resultStr = '',slist=[];
                all_json.forEach(r => {
                    resultStr+=r.Result+',';
                });
                let obj_major = all_json.find(f=>f.Dimensionality===6);
                if(obj_major!=undefined&&obj_major!=null&&obj_major!=''){
                    slist = obj_major.ScoreList;
                }

                API.Career.GetAllroundResult({InterestResultCode: obj_interest.Result,InterestAdviseCode:interest_lowScore,CharacterResultCode:obj_character.Result,AbilityResultCode:this.AllroundAbilityScore}).then(res => {
                    if(res.code==0){
                        if(res.data!=undefined&&res.data!=null&&res.data!=''){
                            this.AllroundInterestResult = res.data.InterestResultList[0];
                            this.AllroundInterestAdvise = res.data.InterestAdviseList;
                            this.AllroundCharacterResult = res.data.CharacterResultList[0];
                            this.GetAbilityOrderData(this.AllroundAbilityScore,res.data.AbilityResultList);// 获取能力成绩排序后的数据
                            this.AllroundAbilityResult.forEach(e => {
                                if(this.getAbilityScoreLowOrHigh(e.Value)=='较弱'){
                                    this.AllroundAbilityLowAdvise.push(res.data.AbilityAdviseList.find(f=>f.Category===e.Category));
                                }
                                else{
                                    this.AllroundAbilityHighAdvise.push(res.data.AbilityAdviseList.find(f=>f.Category===e.Category));
                                }
                            });
                            this.GetValueOrderData(this.AllroundValueScore,res.data.ValueResultList);// 获取价值观成绩排序后的数据
                            this.GetSubjectOrderData(this.AllroundSubjectScore,res.data.SubjectResultList);// 获取学科成绩排序后的数据
                            this.AllroundSubjectResult.forEach(e => {
                                if(this.getSubjectScoreLowOrHigh(e.Value)=='较强'){
                                    this.AllroundSubjectHighAdvise.push(res.data.SubjectResultList.find(f=>f.Subject===e.Subject));
                                }
                                else{
                                    this.AllroundSubjectLowAdvise.push(res.data.SubjectResultList.find(f=>f.Subject===e.Subject));
                                }
                            });

                            //未排名的所有维度符合的专业数据
                            let mlist = [];
                            res.data.MajorList.forEach(d => {
                                let num1 = 0,num2 = 0;
                                if(resultStr.indexOf((d.Interest==''?'000':d.Interest))!=-1||resultStr.indexOf((d.Character==''?'000':d.Character))!=-1||resultStr.indexOf((d.Ability==''?'000':d.Ability))!=-1||resultStr.indexOf((d.Value==''?'000':d.Value))!=-1||resultStr.indexOf((d.Subject==''?'000':d.Subject))!=-1){
                                    num1 += (d.Interest!=''?d.InterestScore:0)+(d.Character!=''?d.CharacterScore:0)+(d.Ability!=''?d.AbilityScore:0)+(d.Value!=''?d.ValueScore:0)+(d.Subject!=''?d.SubjectScore:0);
                                    let zy = slist.find(f=>f.Category===d.Category);
                                    if(zy!=undefined){
                                        num2 = zy.Value;
                                    }
                                    d.TotalNumber=((num1+num2)*d.Factor)+d.Score;
                                    mlist.push(d);
                                }
                            });

                            // 获取兴趣维度推的荐专业
                            let i_list = mlist.filter(f=>(f.Interest=='A'||f.Interest=='C'||f.Interest=='E'||f.Interest=='I'||f.Interest=='R'||f.Interest=='S'));
                            i_list.forEach(i_item => {
                                let i_obj= this.InterestRecommendMajor.find(f=>f.Category==i_item.Category);
                                if(i_obj==undefined){
                                    this.InterestRecommendMajor.push({Category:i_item.Category,MajorList:[]});
                                    let i_obj= this.InterestRecommendMajor.find(f=>f.Category==i_item.Category);
                                    i_obj.MajorList.push(i_item);
                                }
                                else{
                                    i_obj.MajorList.push(i_item);
                                }
                            });
                            
                            // 获取性格维度推的荐专业
                            let c_list = mlist.filter(f=>(f.Character=='E'||f.Character=='F'||f.Character=='I'||f.Character=='J'||f.Character=='N'||f.Character=='P'||f.Character=='S'||f.Character=='T'));
                            c_list.forEach(c_item => {
                                let c_obj= this.CharacterRecommendMajor.find(f=>f.Category==c_item.Category);
                                if(c_obj==undefined){
                                    this.CharacterRecommendMajor.push({Category:c_item.Category,MajorList:[]});
                                    let c_obj= this.CharacterRecommendMajor.find(f=>f.Category==c_item.Category);
                                    c_obj.MajorList.push(c_item);
                                }
                                else{
                                    c_obj.MajorList.push(c_item);
                                }
                            });
                            
                            // 获取能力维度推的荐专业
                            let a_list = mlist.filter(f=>(f.Ability=='空间'||f.Ability=='逻辑'||f.Ability=='内省'||f.Ability=='人际'||f.Ability=='身体'||f.Ability=='音乐'||f.Ability=='语言'||f.Ability=='自然'));
                            a_list.forEach(a_item => {
                                let a_obj= this.AbilityRecommendMajor.find(f=>f.Category==a_item.Category);
                                if(a_obj==undefined){
                                    this.AbilityRecommendMajor.push({Category:a_item.Category,MajorList:[]});
                                    let a_obj= this.AbilityRecommendMajor.find(f=>f.Category==a_item.Category);
                                    a_obj.MajorList.push(a_item);
                                }
                                else{
                                    a_obj.MajorList.push(a_item);
                                }
                            });
                            
                            // 获取价值观维度推的荐专业
                            let v_list = mlist.filter(f=>(f.Value=='安全'||f.Value=='创造'||f.Value=='服务'||f.Value=='管理'||f.Value=='技术'||f.Value=='生活'||f.Value=='挑战'||f.Value=='自主'));
                            v_list.forEach(v_item => {
                                let v_obj= this.ValueRecommendMajor.find(f=>f.Category==v_item.Category);
                                if(v_obj==undefined){
                                    this.ValueRecommendMajor.push({Category:v_item.Category,MajorList:[]});
                                    let v_obj= this.ValueRecommendMajor.find(f=>f.Category==v_item.Category);
                                    v_obj.MajorList.push(v_item);
                                }
                                else{
                                    v_obj.MajorList.push(v_item);
                                }
                            });

                            // 获取学科维度推的荐专业
                            let s_list = mlist.filter(f=>(f.Subject=='地理'||f.Subject=='化学'||f.Subject=='历史'||f.Subject=='生物'||f.Subject=='数学'||f.Subject=='物理'||f.Subject=='英语'||f.Subject=='语文'||f.Subject=='政治'));
                            s_list.forEach(s_item => {
                                let s_obj= this.SubjectRecommendMajor.find(f=>f.Category==s_item.Category);
                                if(s_obj==undefined){
                                    this.SubjectRecommendMajor.push({Category:s_item.Category,MajorList:[]});
                                    let s_obj= this.SubjectRecommendMajor.find(f=>f.Category==s_item.Category);
                                    s_obj.MajorList.push(s_item);
                                }
                                else{
                                    s_obj.MajorList.push(s_item);
                                }
                            });

                            // 获取所有维度专业排序后的数据
                            this.GetMajorOrderData(mlist);
                            this.$nextTick(()=>{
                                setTimeout(() => {
                                    this.html2canvasToImage();// 生成图片
                                }, 1000);
                            });
                        }
                    }
                    else{
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    this.$message.error(err);
                })
            }
        },

        // html2canvas图片转换
        html2canvasToImage(){
            let that = this;
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            html2canvas(document.getElementById('user_result_pdf_box'),{
                useCORS:true
            }).then(function(canvas) {
                that.Print_Box_Img = canvas.toDataURL('image/jpeg', 1.0);
                document.getElementById("result_download_loading").style.display = 'none';
                that.$nextTick(()=>{
                    that.$print(document.getElementById('Print_Box_Img_Id'));
                });
            });
        },

        // 判断兴趣维度高低分数的建议
        GetInterestLowScoreAdvise(num){
            if(4<=num&&num<=9){
                return true;
            }
            else{
                return false;
            }
        },

        // 获取能力成绩排序后的数据
        GetAbilityOrderData(arr,data){
            if(arr.length>0){
                var maxScore = Math.max.apply(Math, arr.map(function(o) {
                    return o.Value;
                }));
                let obj = arr.find(f=>f.Value===maxScore);
                this.AllroundAbilityResult.push({Category:obj.Category,Value:obj.Value, Result:data.find(f=>f.Category==obj.Category).Result});
                arr=arr.filter(f=>f.Category!==obj.Category);
                this.GetAbilityOrderData(arr,data);
            }
        },
        // 能力分数强弱样式转换
        getAbilityClass(num){
            if(4<=num&&num<=9){
                return "top3";
            }
            else if(10<=num&&num<=14){
                return "top2";
            }
            else if(15<=num&&num<=20){
                return "top1";
            }
        },
        // 能力分数强弱排列
        getAbilityScoreLowOrHigh(num){
            if(4<=num&&num<=9){
                return "较弱";
            }
            else if(10<=num&&num<=14){
                return "中等";
            }
            else if(15<=num&&num<=20){
                return "较强";
            }
        },

        // 价值观分数强弱样式转换
        getValueClass(num){
            if(1<=num&&num<=5){
                return "top3";
            }
            else if(6<=num&&num<=10){
                return "top1";
            }
        },
        // 价值观分数强弱排列
        getValueScoreLowOrHigh(num){
            if(1<=num&&num<=5){
                return "较弱";
            }
            else if(6<=num&&num<=10){
                return "较强";
            }
        },
        // 获取价值观成绩排序后的数据
        GetValueOrderData(arr,data){
            if(arr.length>0){
                var maxScore = Math.max.apply(Math, arr.map(function(o) {
                    return o.Value;
                }));
                let obj = arr.find(f=>f.Value===maxScore);
                this.AllroundValueResult.push({Category:obj.Category,Value:obj.Value, Result:data.find(f=>f.Category==obj.Category).Result});
                arr=arr.filter(f=>f.Category!==obj.Category);
                this.GetValueOrderData(arr,data);
            }
        },

        // 学科分数强弱样式转换
        getSubjectClass(num){
            if(1<=num&&num<=5){
                return "top3";
            }
            else if(6<=num&&num<=10){
                return "top1";
            }
        },
        // 学科分数强弱排列
        getSubjectScoreLowOrHigh(num){
            if(1<=num&&num<=5){
                return "较弱";
            }
            else if(6<=num&&num<=10){
                return "较强";
            }
        },
        // 获取学科成绩排序后的数据
        GetSubjectOrderData(arr,data){
            if(arr.length>0){
                var maxScore = Math.max.apply(Math, arr.map(function(o) {
                    return o.Value;
                }));
                let obj = arr.find(f=>f.Value===maxScore);
                this.AllroundSubjectResult.push({Subject:obj.Category,Value:obj.Value, Result:data.find(f=>f.Subject==obj.Category).Result});
                arr=arr.filter(f=>f.Category!==obj.Category);
                this.GetSubjectOrderData(arr,data);
            }
        },

        // 获取所有维度专业排序后的数据
        GetMajorOrderData(arr){
            if(arr.length>0){
                var maxScore = Math.max.apply(Math, arr.map(function(o) {
                    return o.TotalNumber;
                }));
                let obj = arr.find(f=>f.TotalNumber===maxScore);
                this.AllroundMajorList.push({Category:obj.Category,Name:obj.Name, TotalNumber:obj.TotalNumber});
                arr=arr.filter(f=>f.ID!==obj.ID);
                this.GetMajorOrderData(arr);
            }
        },

        // 获取推荐专业图标颜色
        getMajorIconColor(index){
            if(index==0){
                return 'color:#eb312d;';
            }
            else if(index==1){
                return 'color:#fe7002;';
            }
            else if(index==2){
                return 'color:#f7aa4c;';
            }
        },

        // 推荐专业匹配率转换(预留)
        getMatchingRate(num){
            if(num.indexOf('.')!=-1){
                return num.split('.')[0]+'.'+num.split('.')[1].substring(0,2);
            }
            else{
                return num;
            }
        },

        // 兴趣维度代码对应类型转换
        InterestCategoryStr(e){
            if(e=='A'){
                return "艺术型";
            }
            else if(e=='C'){
                return "常规型";
            }
            else if(e=='E'){
                return "企业型";
            }
            else if(e=='I'){
                return "研究型";
            }
            else if(e=='R'){
                return "现实型";
            }
            else if(e=='S'){
                return "社会型";
            }
        },

        // 性格维度代码对应类型转换
        CharacterCategoryStr(e){
            if(e=='E'){
                return "外向";
            }
            else if(e=='F'){
                return "感性";
            }
            else if(e=='I'){
                return "内向";
            }
            else if(e=='J'){
                return "稳定";
            }
            else if(e=='N'){
                return "直觉";
            }
            else if(e=='P'){
                return "顺变";
            }
            else if(e=='S'){
                return "实感";
            }
            else if(e=='T'){
                return "理性";
            }
        },

        // 兴趣分数图
        GetResultChartBoxXQ(data){
            const chartDom = document.getElementById("ResultChartBoxXQ");
            const myChart = this.$echarts.init(chartDom);
            let option = {
                radar: {
                    indicator: [
                        { name: '艺术型(A)', max: 20, color:'#111' },
                        { name: '常规型(C)', max: 20, color:'#111' },
                        { name: '企业型(E)', max: 20, color:'#111' },
                        { name: '研究型(I)', max: 20, color:'#111' },
                        { name: '现实型(R)', max: 20, color:'#111'},
                        { name: '社会型(S)', max: 20, color:'#111' }
                    ]
                },
                series: [
                    {
                        type: 'radar',
                        color:'rgba('+getStore('ThemeColor')+',0.85)',
                        data: [
                            {
                                value: data
                            }
                        ]
                    }
                ]
            }
            option && myChart.setOption(option);
        }
    }
}
</script>
<style lang="less" scoped>

.test_result_box{
    max-width: 1000px;
    margin: auto;
    .test_result_con{
        width: 100%;
        background-color: #fff;
        .user_result_all{
            width: 100%;
            margin: auto;
            overflow: hidden;
            box-shadow: 0 0 10px 1px rgba(232, 76, 51,0.3);
            background-color: #fff;
        }
        .user_result_box{
            width: 100%;
            position: relative;
            background-color: #fff;
            .print_box{
                position: absolute;
                z-index: 2;
                left: 0px;
                top: 0px;
                img{
                    width: 100%;
                    display: block;
                    border: 0px;
                }
            }
            .radius1{
                position: absolute;
                width: 200px;
                height: 200px;
                border-radius: 100px;
                background-color: rgba(var(--themecolor),0.1);
                top: -110px;
                left: -110px;
                z-index: 0;
            }
            .radius2{
                position: absolute;
                width: 200px;
                height: 200px;
                border-radius: 100px;
                background-color: rgba(var(--themecolor),0.1);
                top: 550px;
                left: -110px;
                z-index: 0;
            }
            .radius3{
                position: absolute;
                width: 120px;
                height: 120px;
                border-radius: 120px;
                background-color: rgba(var(--themecolor),0.08);
                top: 480px;
                left: -75px;
                z-index: 0;
            }
            .radius4{
                position: absolute;
                width: 60px;
                height: 60px;
                border-radius: 60px;
                background-color: rgba(var(--themecolor),0.12);
                top: 735px;
                left: -35px;
                z-index: 0;
            }
            .radius5{
                position: absolute;
                width: 200px;
                height: 200px;
                border-radius: 100px;
                background-color: rgba(var(--themecolor),0.1);
                top: 810px;
                right: -110px;
                z-index: 0;
            }
            .radius6{
                position: absolute;
                width: 120px;
                height: 120px;
                border-radius: 120px;
                background-color: rgba(var(--themecolor),0.08);
                top: 965px;
                right: -75px;
                z-index: 0;
            }
            .radius7{
                position: absolute;
                width: 60px;
                height: 60px;
                border-radius: 60px;
                background-color: rgba(var(--themecolor),0.12);
                top: 765px;
                right: -35px;
                z-index: 0;
            }
            .radius8{
                position: absolute;
                width: 150px;
                height: 150px;
                border-radius: 150px;
                background-color: rgba(var(--themecolor),0.1);
                top: 1100px;
                left: -110px;
                z-index: 0;
            }
            .user_box{
                width: 100%;
                height: 350px;
                background: url(../../../../src/assets/images/result-head.jpg) no-repeat center top / cover;
                .info{
                    margin-left: 80px;
                    margin-bottom: 25px;
                    .t{
                        font-size: 16px;
                        color: #444;
                    }
                    .x{
                        font-size: 24px;
                        color: #222;
                    }
                }
            }
            .title_box{
                color: #333;
                font-size: 50px;
                font-weight: bold;
                letter-spacing: 8px;
                margin: 60px 0 0px 0px;
                text-align: center;
            }
            .type_box{
                margin-top: 150px;
                padding-bottom: 300px;
                .tbox{
                    margin: 0px 20px;
                    color: #333;
                    font-size: 30px;
                    margin-bottom: 50px;
                    text-align: center;
                }
            }
            .type_con{
                margin: 0px 50px;
                .type_name{
                    color: #333;
                    font-size: 30px;
                }
                .type_mark{
                    border: 1px solid #bebdbd;
                    border-radius: 10px;
                    position: relative;
                    margin-top: 50px;
                    .mark_tips{
                        position: absolute;
                        left: 48px;
                        top: -14px;
                        color: #333;
                        font-size: 18px;
                        background-color: #fff;
                        padding: 0px 10px;
                    }
                    .mark_txt{
                        color: #333;
                        font-size: 14px;
                        line-height: 24px;
                        padding: 20px 20px 20px;
                    }
                }
                .type_result{
                    margin-top: 50px;
                    .tit_t{
                        color: #333;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .score_box{
                        margin-top: 30px;
                        .s_tit{
                            color: #333;
                            font-size: 18px;
                            width: 56px;
                        }
                        .interestR{background-color: #3db7c2;}
                        .interestI{background-color: #6798e0;}
                        .interestA{background-color: #f07a62;}
                        .interestS{background-color: #ec9f3a;}
                        .interestE{background-color: #d3c63b;}
                        .interestC{background-color: #6cd44a;}
                        .score{
                            .s{
                                text-align: center;
                                width: calc(((100% - 56px) / 6) - 20px);
                                float: left;
                                border-radius: 5px;
                                margin: 0px 10px;
                                .z{
                                    display: block;
                                    font-size: 32px;
                                    font-weight: bold;
                                    color: #fff;
                                    margin-top: 5px;
                                }
                                .t{
                                    display: block;
                                    font-size: 13px;
                                    color: #fff;
                                    margin: 2px 0 5px;
                                }
                                .n{
                                    display: block;
                                    font-size: 32px;
                                    color: #fff;
                                    margin-bottom: 10px;
                                    .f{
                                        font-size: 16px;
                                        color: #fff;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                        .df_box{
                            float: left;
                            width: calc(100% - 450px);
                            .xqlx{
                                width: 100%;
                                margin-top: 30px;
                                height: 50px;
                                line-height: 50px;
                                .tt{
                                    color: #333;
                                    font-size: 18px;
                                }
                                .jl_e{
                                    color: rgba(var(--themecolor),0.9);
                                    font-size: 30px;
                                    font-weight: bold;
                                    letter-spacing: 10px;
                                }
                                .ch{
                                    font-size: 28px;
                                    letter-spacing: 0px;
                                }
                                .jl_h{
                                    color: #333;
                                    font-size: 14px;
                                }
                                .jl_g{
                                    color: rgba(var(--themecolor),0.85);
                                    font-size: 18px;
                                }
                            }
                            .jlmark{
                                color: #333;
                                font-size: 16px;
                                line-height: 30px;
                                margin-top: 50px;
                            }
                        }
                        .ld_box{
                            width: 400px;
                            float: right;
                            .xqld{
                                margin-top: 30px;
                                text-align: center;
                                color: rgba(var(--themecolor),0.9);
                                font-size: 20px;
                                width: 100%;
                                text-align: center;
                                display: block;
                                height: 30px;
                            }
                            .cbox{
                                width: 400px;
                                height: 400px;
                            }
                        }
                        .type_table{
                            width: 100%;
                            border-top: 1px solid #bebdbd;
                            border-right: 1px solid #bebdbd;
                            margin-top: 10px;
                            .list{
                                width: 100%;
                                border-left: 1px solid #bebdbd;
                                border-bottom: 1px solid #bebdbd;
                                position: relative;
                                .bt{
                                    height: 40px;
                                    line-height: 40px;
                                    color: #333;
                                    font-size: 16px;
                                    font-weight: bold;
                                    text-align: center;
                                    float: left;
                                }
                                .bt1{
                                    width: 60px;
                                }
                                .bt2{
                                    width: 250px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .bt3{
                                    width: 150px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .bt4{
                                    width: calc(100% - 463px);
                                    border-left: 1px solid #bebdbd;
                                }
                                .head{
                                    width: 100px;
                                    display: inline-block;
                                    height: 100%;
                                    .ttbox{
                                        position: absolute;
                                        left: 15px;
                                        top: calc(50% - 35px);
                                        width: 70px;
                                        height: 70px;
                                        text-align: center;
                                        border-radius: 5px;
                                        .e{
                                            font-size: 30px;
                                            color: #fff;
                                            margin-top: 3px;
                                        }
                                        .z{
                                            font-size: 14px;
                                            color: #fff;
                                        }
                                        .y{
                                            color: rgba(var(--themecolor),0.85);
                                            font-size: 20px;
                                            font-weight: bold;
                                            line-height: 70px;
                                        }
                                        .j{
                                            font-size: 16px;
                                            color: rgba(var(--themecolor),0.85);
                                            font-weight: bold;
                                            margin-top: 13px;
                                            margin-bottom: 3px;
                                        }
                                        .s{
                                            font-size: 14px;
                                            color: #333;
                                            margin-top: 5px;
                                        }
                                    }
                                    .zyfx{
                                        height: 30px;
                                        line-height: 30px;
                                        top: calc(50% - 15px);
                                        .x{
                                            font-size: 16px;
                                            color: rgba(var(--themecolor),0.85);
                                            font-weight: bold;
                                        }
                                        .n{
                                            color: #333;
                                        }
                                        .p{
                                            font-size: 16px;
                                            color: #333;
                                        }
                                    }
                                }
                                .text{
                                    width: calc(100% - 102px);
                                    border-left: 1px solid #bebdbd;
                                    display: inline-block;
                                    .tz{
                                        border-bottom: 1px solid #bebdbd;
                                        position: relative;
                                        .t{
                                            width: 100px;
                                            display: inline-block;
                                            height: 100%;
                                            span{
                                                display: block;
                                                color: #333;
                                                font-size: 15px;
                                                height: 20px;
                                                line-height: 20px;
                                                position: absolute;
                                                left: 0px;
                                                top: calc(50% - 10px);
                                                text-align: right;
                                                width: 100px;
                                            }
                                        }
                                        .x{
                                            display: inline-block;
                                            border-left: 1px solid #bebdbd;
                                            padding: 10px 15px;
                                            line-height: 26px;
                                            color: #333;
                                            font-size: 15px;
                                        }
                                        .m{
                                            display: inline-block;
                                            padding: 15px 15px;
                                            line-height: 26px;
                                            color: #333;
                                            font-size: 15px;
                                        }
                                    }
                                    .tz:last-child{
                                        border-bottom:none;
                                    }
                                }
                            }
                            .order{
                                width: 100%;
                                border-left: 1px solid #bebdbd;
                                border-bottom: 1px solid #bebdbd;
                                position: relative;
                                .sj{
                                    height: 40px;
                                    line-height: 40px;
                                    color: #333;
                                    font-size: 15px;
                                    text-align: center;
                                    float: left;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                    position: relative;
                                    .n{
                                        color: #333;
                                        font-size: 13px;
                                        position: absolute;
                                        z-index: 1;
                                    }
                                    i{
                                        font-size: 27px;
                                        position: absolute;
                                        left: 20px;
                                        top: 0px;
                                        display: block;
                                        z-index: 0;
                                    }
                                }
                                .sj1{
                                    width: 60px;
                                }
                                .sj2{
                                    width: 250px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .sj3{
                                    width: 150px;
                                    border-left: 1px solid #bebdbd;
                                }
                                .sj4{
                                    width: calc(100% - 463px);
                                    border-left: 1px solid #bebdbd;
                                }
                            }
                        }
                        .bt_tit{
                            color: #333;
                            font-size: 18px;
                            padding-top: 30px;
                            margin-bottom: 20px;
                        }
                        .tj_type{
                            width: 100%;
                            .tj_tit{
                                color: #333;
                                font-size: 16px;
                                height: 30px;
                                line-height: 30px;
                                position: relative;
                                text-indent: 12px;
                                .tj_ico{
                                    height: 16px;
                                    width: 5px;
                                    background-color: rgba(var(--themecolor),0.85);
                                    position: absolute;
                                    top: 7px;
                                    left: 0px;
                                }
                            }
                            .tj_box{
                                width: 100%;
                                margin-bottom: 15px;
                                .b{
                                    display: inline-block;
                                    background-color: rgba(var(--themecolor),0.15);
                                    color: #555;
                                    font-size: 14px;
                                    padding: 2px 12px;
                                    border-radius: 20px;
                                    margin: 7px 10px;
                                }
                            }
                        }
                        .s_chart_box{
                            height: 200px;
                            margin-bottom: 50px;
                            margin-top: 20px;
                            position: relative;
                        }
                        .xg_l_box{
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            width: 800px;
                            height: 100%;
                            margin-left: 60px;
                            .l_box{
                                width: 100%;
                                height: calc(20% - 1px);
                                border-top: 1px dashed #c5c4c4;
                                position: relative;
                                span{
                                    position: absolute;
                                    left: -45px;
                                    color: #333;
                                    font-size: 13px;
                                    top: -11px;
                                    display: block;
                                    width: 40px;
                                    text-align: right;
                                }
                            }
                        }
                        .xg_s_box{
                            width: 800px;
                            height: 100%;
                            margin-left: 60px;
                            border-left: 1px solid #bebdbd;
                            border-bottom: 1px solid #bebdbd;
                            position: relative;
                            .xg_item{
                                width: 80px;
                                position: absolute;
                                bottom: 0px;
                                height: 100%;
                                .item{
                                    width: 40px;
                                    display: inline-block;
                                    position: relative;
                                    bottom: 0px;
                                    height: 100%;
                                    .name{
                                        color: #333;
                                        font-size: 13px;
                                        position: absolute;
                                        bottom: -36px;
                                        left: 0px;
                                        text-align: center;
                                        width: 100%;
                                    }
                                    .number{
                                        position: absolute;
                                        width: 22px;
                                        bottom: 0px;
                                        left: 9px;
                                        .num{
                                            position: relative;
                                            top: -20px;
                                            left: 0px;
                                            width: 100%;
                                            text-align: center;
                                            display: block;
                                            font-size: 13px;
                                            color: #333;
                                        }
                                        .tip{
                                            position: relative;
                                            top: -40px;
                                            left: -10px;
                                            width: 40px;
                                            text-align: center;
                                            display: block;
                                            font-size: 12px;
                                            color: #333;
                                        }
                                        .tip.top1{
                                            color: rgba(var(--themecolor),0.9);
                                        }
                                        .tip.top2{
                                            color: #a75304;
                                        }
                                        .tip.top3{
                                            color: #585858;
                                        }
                                    }
                                }
                                .item:nth-child(n+1) .number{
                                    background-color: rgb(19, 156, 156);
                                }
                                .item:nth-child(n+2) .number{
                                    background-color: rgb(54, 79, 148);
                                }
                            }
                            .xg_item:nth-child(n+1){
                                left: 90px;
                            }
                            .xg_item:nth-child(n+2){
                                left: 270px;
                            }
                            .xg_item:nth-child(n+3){
                                left: 450px;
                            }
                            .xg_item:nth-child(n+4){
                                left: 630px;
                            }
                            .nl_item{
                                width: 40px;
                                .item{
                                    .name{
                                        bottom: -20px;
                                    }
                                }
                            }
                            .nl_item .item:nth-child(n+1) .number{
                                background-color: #3c8b22;
                            }
                            .nl_item:nth-child(n+1){
                                left: 60px;
                            }
                            .nl_item:nth-child(n+2){
                                left: 150px;
                            }
                            .nl_item:nth-child(n+3){
                                left: 240px;
                            }
                            .nl_item:nth-child(n+4){
                                left: 330px;
                            }
                            .nl_item:nth-child(n+5){
                                left: 420px;
                            }
                            .nl_item:nth-child(n+6){
                                left: 510px;
                            }
                            .nl_item:nth-child(n+7){
                                left: 600px;
                            }
                            .nl_item:nth-child(n+8){
                                left: 690px;
                            }
                            .zy_item .item:nth-child(n+1) .number{
                                background-color: #642f79;
                            }
                            .xk_item .item:nth-child(n+1) .number{
                                background-color: #1f71a3;
                            }
                            .xk_item:nth-child(n+1){
                                left: 55px;
                            }
                            .xk_item:nth-child(n+2){
                                left: 135px;
                            }
                            .xk_item:nth-child(n+3){
                                left: 215px;
                            }
                            .xk_item:nth-child(n+4){
                                left: 295px;
                            }
                            .xk_item:nth-child(n+5){
                                left: 375px;
                            }
                            .xk_item:nth-child(n+6){
                                left: 455px;
                            }
                            .xk_item:nth-child(n+7){
                                left: 535px;
                            }
                            .xk_item:nth-child(n+8){
                                left: 615px;
                            }
                            .xk_item:nth-child(n+9){
                                left: 695px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>